import { Link, useSearchParams } from "@remix-run/react";
import { motion } from "framer-motion";
import { useState } from "react";
import { cn } from "~/lib/utils";
import { Button } from "./ui/button";

export const Header = () => {
  const [searchParams] = useSearchParams();

  if (import.meta.env.VITE_FEATURE_V2 === "true") {
    return <Header_v2 />;
  }

  return (
    <header className="sticky top-0 z-50 flex h-[52px] items-center bg-white px-5 py-3">
      <Link
        to={{
          pathname: "/",
          search: searchParams.toString(),
        }}
      >
        <BrandLogo />
      </Link>
    </header>
  );
};

export const Header_v2 = () => {
  const [searchParams] = useSearchParams();
  const [hoveredItem, setHoveredItem] = useState<{
    label: string;
    component: JSX.Element;
  } | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isTransitioning, setIsTransitioning] = useState(false);

  const handleMenuToggle = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsMenuOpen(!isMenuOpen);
      setTimeout(() => {
        setIsTransitioning(false);
      }, 150);
    }, 150);
  };

  return (
    <>
      <header className="sticky top-0 z-[9999] max-w-[100vw] border-[#161615]/15 border-b bg-white px-5 lg:px-[20px]">
        <div className="relative h-[60px] md:h-[68px] md:pl-[166px]">
          <Link
            onClick={() => isMenuOpen && setIsMenuOpen(false)}
            to="/"
            className="-translate-y-1/2 absolute top-1/2 left-0"
            aria-label="ホームページへ"
          >
            <BrandLogo />
          </Link>
          <nav aria-label="メインナビゲーション">
            <ul className="mx-auto hidden h-full max-w-[1250px] items-center gap-[min(6.51vw,52px)] md:flex md:h-[68px] xl:gap-[72px] 2xl:ml-[clamp(0px,calc((100vw-1456px)/2),242px)]">
              <li
                onMouseEnter={() =>
                  setHoveredItem({ label: "私たちの考え", component: <></> })
                }
                onMouseLeave={() => setHoveredItem(null)}
                className="relative flex h-10 items-center gap-2 font-semibold text-[18px] leading-[21px] hover:h-20"
              >
                私たちの考え
                <ArrowDownIcon
                  isActive={hoveredItem?.label === "私たちの考え"}
                  className="transition-transform duration-300 ease-in-out"
                  aria-hidden="true"
                />
                {hoveredItem?.label === "私たちの考え" && (
                  <div
                    className="-translate-x-1/2 absolute top-[75px] left-1/2 z-10 w-max transform rounded-[10px] bg-white py-3 text-[16px]"
                    role="menu"
                  >
                    <ul className="flex flex-col">
                      {[
                        "なんのためにやるか",
                        "余白をつくるHTN理論",
                        "クレジット",
                      ].map((category, index) => (
                        <li key={category}>
                          <Link
                            to={
                              index === 0
                                ? "/philosophy"
                                : index === 1
                                  ? "/blog/theory"
                                  : "/credit"
                            }
                            onClick={() => {
                              setIsMenuOpen(false);
                              setTimeout(() => {
                                const element =
                                  document.querySelector("#blog-content");
                                if (element) {
                                  const targetPosition =
                                    element.getBoundingClientRect().top +
                                    window.scrollY -
                                    150;
                                  window.scrollTo({
                                    top: targetPosition,
                                    behavior: "smooth",
                                  });
                                }
                              }, 1000);
                            }}
                            className="block w-full cursor-pointer px-6 py-3 transition-opacity duration-300 hover:bg-gray-light"
                            role="menuitem"
                          >
                            {category}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </li>
              <li className="relative flex h-10 items-center gap-2 font-semibold text-[18px] leading-[21px]">
                <Link
                  to={"/blog"}
                  className="transition-all duration-300 hover:opacity-50"
                >
                  ブログ
                </Link>
              </li>
              {["プロダクト"].map((item) => (
                <li
                  key={item}
                  className="flex h-10 items-center justify-center text-[#161615]/40 text-[18px] leading-[21px]"
                >
                  <span className="font-semibold">{item}</span>
                  <span className="ml-[6px] font-semibold text-[12px]">
                    Coming Soon
                  </span>
                </li>
              ))}
            </ul>
          </nav>
          <Button
            v2
            className={cn(
              "-translate-y-1/2 absolute top-1/2 right-0 hidden h-[40px] w-[170px] font-light text-base md:flex",
            )}
            asChild
          >
            <Link to="/#waitlist">ウェイトリスト登録</Link>
          </Button>
          <button
            type="button"
            className="-translate-y-1/2 absolute top-1/2 right-0 block md:hidden"
            onClick={handleMenuToggle}
            aria-expanded={isMenuOpen}
            aria-label={isMenuOpen ? "メニューを閉じる" : "メニューを開く"}
          >
            <svg
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
            >
              <path
                d={
                  isMenuOpen
                    ? "M5 15H25"
                    : isTransitioning
                      ? "M5 15H25"
                      : "M5 8H25"
                }
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                className="transition-all duration-300"
                style={{
                  transformOrigin: "center",
                  transform: isMenuOpen ? "rotate(45deg)" : "none",
                }}
              />
              <path
                d="M5 15H25"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                className="transition-all duration-300"
                style={{
                  opacity: isTransitioning || isMenuOpen ? 0 : 1,
                }}
              />
              <path
                d={
                  isMenuOpen
                    ? "M5 15H25"
                    : isTransitioning
                      ? "M5 15H25"
                      : "M5 22H25"
                }
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                className="transition-all duration-300"
                style={{
                  transformOrigin: "center",
                  transform: isMenuOpen ? "rotate(-45deg)" : "none",
                }}
              />
            </svg>
          </button>
        </div>
      </header>

      {/* SPサイズ用のハンバーガーメニュー */}
      {isMenuOpen && (
        <nav
          className="fixed left-0 z-[9998] h-screen w-full bg-white pt-[60px] md:hidden"
          aria-label="モバイルナビゲーション"
        >
          <ul className="flex flex-col gap-8 px-4 py-8">
            <li>
              <Link
                to="/blog"
                className="font-semibold text-h3-sp"
                onClick={() => setIsMenuOpen(false)}
              >
                私たちの考え
              </Link>
              <ul className="mt-6 flex flex-col gap-4 pl-4 font-semibold text-h4-sp">
                {[
                  { path: "/philosophy", label: "なんのためにやるか" },
                  { path: "/blog/theory", label: "余白を作るHTN理論" },
                  { path: "/credit", label: "クレジット" },
                ].map(({ path, label }) => (
                  <li key={path}>
                    <Link
                      to={path}
                      onClick={() => {
                        setIsMenuOpen(false);
                        setTimeout(() => {
                          const element =
                            document.querySelector("#blog-content");
                          if (element) {
                            const targetPosition =
                              element.getBoundingClientRect().top +
                              window.scrollY -
                              150;
                            window.scrollTo({
                              top: targetPosition,
                              behavior: "smooth",
                            });
                          }
                        }, 1000);
                      }}
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <Link
                to="/blog"
                className="font-semibold text-h3-sp"
                onClick={() => setIsMenuOpen(false)}
              >
                ブログ
              </Link>
            </li>
            {["プロダクト"].map((item) => (
              <li key={item} className="text-sm">
                <span className="font-semibold text-[#161615]/40 text-h3-sp">
                  {item}
                </span>
                <span className="block text-[#161615]/40 text-small-sp">
                  Coming Soon
                </span>
              </li>
            ))}
          </ul>
        </nav>
      )}
      {hoveredItem && (
        <motion.div
          className="fixed top-[68px] z-[9999] w-screen"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onMouseEnter={() => setHoveredItem(hoveredItem)}
          onMouseLeave={() => setHoveredItem(null)}
          role="dialog"
          aria-modal="true"
        >
          {hoveredItem.component}
        </motion.div>
      )}
    </>
  );
};

const items = [
  {
    label: "sattoへの想い",
    component: (
      <div className="flex items-start justify-center gap-10 bg-gray-light py-[50px]">
        <div>
          <ul className="flex w-[464px] flex-col gap-6">
            <li>
              <Link to="/" className="flex flex-col gap-2">
                <h3 className="">sattoのフィロソフィー</h3>
                <h4 className="">テキストが入りますテキストが入ります</h4>
              </Link>
            </li>
            <li>
              <Link to="/" className="flex flex-col gap-2">
                <h3 className="">sattoのセオリー</h3>
                <h4 className="">テキストが入りますテキストが入ります</h4>
              </Link>
            </li>
            <li>
              <Link to="/" className="flex flex-col gap-2">
                <h3 className="">ロードマップ</h3>
                <h4 className="">テキストが入りますテキストが入ります</h4>
              </Link>
            </li>
          </ul>
        </div>
        <svg
          width="525"
          height="295"
          viewBox="0 0 525 295"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="h-[295px] w-[525px]"
        >
          <rect width="525" height="295" fill="#D9D9D9" />
          <path
            d="M232.962 136.77L205.949 186H319.051L288.756 109L260.733 168.075L232.962 136.77Z"
            fill="#BEBEBE"
          />
          <circle cx="231.195" cy="118.846" r="9.8459" fill="#BEBEBE" />
        </svg>
      </div>
    ),
  },
  {
    label: "プロダクト",
    component: (
      <div className="flex items-start justify-center gap-10 bg-gray-light py-[50px]">
        {/* TODO: 未実装 */}
        未実装
      </div>
    ),
  },
  {
    label: "記事",
    component: (
      <div className="flex items-start justify-center gap-10 bg-gray-light py-[50px]">
        {/* TODO: 未実装 */}
        未実装
      </div>
    ),
  },
];

/*　----------------- svg　 componets -----------------*/

const ArrowDownIcon = ({
  isActive,
  className,
}: { isActive: boolean; className?: string }) => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(isActive ? "rotate-180" : "", className)}
    >
      <path
        d="M1 1L5 5L9 1"
        stroke="#C7BCB8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

const BrandLogo = () => {
  return (
    <svg
      className="md:h-[27px] md:w-[86px]"
      width="67"
      height="20"
      viewBox="0 0 86 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.13266 24.5527C2.87594 23.9885 1.89716 23.2083 1.16004 22.236C0.435017 21.2637 0.0483352 20.1714 0 18.9591H4.30183C4.37433 19.7993 4.78518 20.5075 5.5223 21.0717C6.25941 21.6358 7.18986 21.9239 8.31365 21.9239C9.43745 21.9239 10.38 21.6959 11.0325 21.2517C11.6729 20.8076 11.9992 20.2314 11.9992 19.5352C11.9992 18.791 11.6367 18.2268 10.9238 17.8667C10.2108 17.5066 9.07493 17.1105 7.51612 16.6664C6.00565 16.2583 4.78518 15.8622 3.83056 15.466C2.88803 15.0819 2.06633 14.4938 1.36547 13.6895C0.676693 12.8973 0.326262 11.841 0.326262 10.5446C0.326262 9.4883 0.640442 8.51601 1.2688 7.62775C1.89716 6.75149 2.80344 6.05529 3.98765 5.55114C5.15978 5.047 6.52525 4.79492 8.04781 4.79492C10.3316 4.79492 12.1684 5.37109 13.5701 6.51142C14.9718 7.66376 15.7089 9.22422 15.8056 11.1928H11.6488C11.5763 10.3045 11.2138 9.58432 10.5612 9.05617C9.90871 8.52801 9.01451 8.26394 7.9028 8.26394C6.79109 8.26394 5.9694 8.468 5.38937 8.87612C4.80935 9.28424 4.51934 9.82439 4.51934 10.5086C4.51934 11.0367 4.71268 11.4809 5.09936 11.853C5.48604 12.2131 5.95731 12.5012 6.52525 12.7052C7.08111 12.9093 7.91489 13.1734 9.00243 13.4855C10.4646 13.8696 11.6609 14.2657 12.5913 14.6618C13.5218 15.0579 14.3314 15.6461 15.0202 16.4143C15.6969 17.1825 16.0473 18.2148 16.0715 19.4872C16.0715 20.6276 15.7573 21.6358 15.1289 22.5241C14.5006 23.4124 13.6064 24.1206 12.4463 24.6247C11.2863 25.1289 9.93288 25.3809 8.38616 25.3809C6.83943 25.3809 5.38937 25.0929 4.14474 24.5287L4.13266 24.5527Z"
        fill="#161615"
      />
      <path
        d="M34.7702 5.13102V7.4717C33.6101 6.4274 32.2084 5.64717 30.6617 5.20304C29.7917 4.93896 28.8491 4.79492 27.8099 4.79492C27.4837 4.79492 27.1695 4.81893 26.8674 4.84294C26.807 4.84294 26.7465 4.84293 26.6861 4.85494C26.5895 4.85494 26.4928 4.87895 26.3961 4.89095C21.3451 5.57515 17.4541 9.88441 17.4541 15.0819C17.4541 20.2794 21.0551 24.2526 25.8161 25.1769C25.9369 25.2009 26.0698 25.2249 26.1907 25.2489C26.2149 25.2489 26.2269 25.2489 26.2511 25.2489C26.7345 25.3209 27.2299 25.3689 27.7495 25.3689C27.9549 25.3689 28.1603 25.3689 28.3537 25.3569C30.8188 25.2369 33.0664 24.2526 34.7823 22.7042V25.0448H38.9753V5.10701H34.7823L34.7702 5.13102ZM28.1362 21.8159C24.4748 21.8159 21.5022 18.815 21.5022 15.1059C21.5022 11.3969 24.4748 8.39597 28.1362 8.39597C31.7976 8.39597 34.7702 11.3969 34.7702 15.1059C34.7702 18.815 31.7976 21.8159 28.1362 21.8159Z"
        fill="#161615"
      />
      <path
        d="M50.5575 24.7453C49.6874 25.1534 48.4791 25.4055 47.1377 25.4055C45.3373 25.4055 43.8026 24.8413 42.8601 23.3889C42.5217 22.8728 42.2559 22.2366 42.0867 21.4804C42.0867 21.4323 42.0626 21.3723 42.0505 21.3123C42.0021 21.0602 41.9538 20.7841 41.9296 20.4961C41.9296 20.448 41.9296 20.412 41.9175 20.364C41.9175 20.292 41.9055 20.22 41.9055 20.148C41.9055 20.1119 41.9055 20.0879 41.9055 20.0519C41.9055 19.9079 41.8813 19.7518 41.8813 19.5958C41.8813 19.5718 41.8813 19.5598 41.8813 19.5358C41.8813 19.3917 41.8813 19.2357 41.8813 19.0796C41.8813 19.0796 41.8813 19.0676 41.8813 19.0556C41.8813 18.9236 41.8813 18.7796 41.8813 18.6355C41.9055 15.7067 42.5217 12.0216 43.4522 8.58858H41.3496C41.3496 7.71232 41.6396 6.37994 42.2801 5.11957H44.5518C45.0231 3.81119 45.5427 2.61083 46.0744 1.60254H50.5212C50.3158 1.95064 49.772 3.37906 49.4216 4.30333C49.3007 4.61542 49.192 4.9035 49.1195 5.11957H52.5271C51.8867 6.37994 51.5967 7.71232 51.5967 8.58858H48.0199C47.2465 11.2894 46.6544 14.0622 46.449 16.3308C46.3523 17.4472 46.3402 18.4315 46.449 19.2357C46.449 19.3077 46.4731 19.3677 46.4852 19.4398C46.5094 19.5838 46.5577 19.7158 46.6061 19.8479C46.6302 19.9079 46.6544 19.9799 46.6907 20.0399C46.8236 20.304 47.0048 20.5321 47.2223 20.7241C47.464 20.9282 47.7419 21.0962 48.0561 21.2163C48.2495 21.2883 48.4549 21.3483 48.6724 21.3843C49.4578 21.5164 50.352 21.3963 51.2221 20.9762L50.5695 24.7093L50.5575 24.7453Z"
        fill="#161615"
      />
      <path
        d="M73.9722 4.80859C68.1719 4.80859 63.5801 9.48996 63.5801 15.1316C63.5801 20.7733 68.2565 25.4066 73.9722 25.4066C79.6878 25.4066 84.4488 20.7613 84.4488 15.1316C84.4488 9.50197 79.7361 4.80859 73.9722 4.80859ZM74.0205 21.5655C70.4558 21.5655 67.6644 18.6246 67.6644 15.1676C67.6644 11.7106 70.3712 8.68573 74.0205 8.68573C77.6698 8.68573 80.3766 11.5786 80.3766 15.0836C80.3766 18.5886 77.6215 21.5655 74.0205 21.5655Z"
        fill="#161615"
      />
      <path
        d="M62.1395 24.7453C61.2695 25.1534 60.0611 25.4055 58.7198 25.4055C56.9193 25.4055 55.3847 24.8413 54.4421 23.3889C54.1038 22.8728 53.8379 22.2366 53.6688 21.4804C53.6688 21.4323 53.6446 21.3723 53.6325 21.3123C53.5842 21.0602 53.5358 20.7841 53.5117 20.4961C53.5117 20.448 53.5117 20.412 53.4996 20.364C53.4996 20.292 53.4875 20.22 53.4875 20.148C53.4875 20.1119 53.4875 20.0879 53.4875 20.0519C53.4875 19.9079 53.4633 19.7518 53.4633 19.5958C53.4633 19.5718 53.4633 19.5598 53.4633 19.5358C53.4633 19.3917 53.4633 19.2357 53.4633 19.0796C53.4633 19.0796 53.4633 19.0676 53.4633 19.0556C53.4633 18.9236 53.4633 18.7796 53.4633 18.6355C53.4875 15.7067 54.1038 12.0216 55.0342 8.58858H52.9316C52.9316 7.71232 53.2217 6.37994 53.8621 5.11957H56.1338C56.6051 3.81119 57.1247 2.61083 57.6564 1.60254H62.1032C61.8978 1.95064 61.354 3.37906 61.0036 4.30333C60.8828 4.61542 60.774 4.9035 60.7015 5.11957H64.1092C63.4687 6.37994 63.1787 7.71232 63.1787 8.58858H59.6019C58.8285 11.2894 58.2364 14.0622 58.031 16.3308C57.9343 17.4472 57.9223 18.4315 58.031 19.2357C58.031 19.3077 58.0552 19.3677 58.0673 19.4398C58.0914 19.5838 58.1398 19.7158 58.1881 19.8479C58.2123 19.9079 58.2364 19.9799 58.2727 20.0399C58.4056 20.304 58.5869 20.5321 58.8044 20.7241C59.046 20.9282 59.324 21.0962 59.6382 21.2163C59.8315 21.2883 60.0369 21.3483 60.2544 21.3843C61.0399 21.5164 61.9341 21.3963 62.8041 20.9762L62.1516 24.7093L62.1395 24.7453Z"
        fill="#161615"
      />
    </svg>
  );
};
